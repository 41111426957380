<template>
    <div>
        <AdminDashboard :show_data="false"/>
    </div>
</template>

<script>
    
    import AdminDashboard from './AdminDashboard.vue';
    export default {
        components: {
            AdminDashboard
        },
    };
</script>

<style scoped>
    
</style>